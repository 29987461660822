import { ApiError, getResponseData, handleApiErrors, jkasseApi } from '@/services/api/api'
import { authServices } from '@/services/api/auth/api'
import { Seller } from '@/store/modules/seller/interfaces'

/**
 * get user seller stat for current user
 */
async function getUserSellerStatApi (): Promise<Seller> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.get('/userstats/getUserSellerStat', config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.userSellerStat) {
        return Promise.resolve(data.data.userSellerStat)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

export {
  getUserSellerStatApi
}
