
import { defineComponent, ref, toRef, toRefs, watch } from 'vue'
import { createOrUpdatePerson } from '@/services/api/person/api'
import { ApiResponse } from '@/services/api/api'
import { createOrUpdateAddress } from '@/services/api/address/api'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import { Person } from '@/services/api/person/interfaces'
import { Address } from '@/services/api/address/interfaces'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ShopPersonAddressForm',
  props: {
    user: Object,
    person: Object,
    address: Object,
    edit: Boolean,
    loading: Boolean
  },
  setup (props, { emit }) {
    const router = useRouter()
    const { meta } = useForm()
    const {
      value: firstname,
      errorMessage: firstnameError,
      meta: firstnameMeta
    } = useField(
      'firstname', {
        required: true,
        alpha_dash: true,
        min: 2
      }, { label: 'Vorname' })
    const {
      value: lastname,
      errorMessage: lastnameError,
      meta: lastnameMeta
    } = useField(
      'lastname', {
        required: true,
        alpha_dash: true,
        min: 2
      }, { label: 'Nachname' })
    const {
      value: phone,
      errorMessage: phoneError,
      meta: phoneMeta
    } = useField(
      'phone', {
        required: true,
        alpha_dash: true,
        min: 6
      }, { label: 'Telefon' })
    const {
      value: street,
      errorMessage: streetError,
      meta: streetMeta
    } = useField(
      'street', {
        required: true,
        min: 3
      }, { label: 'Strasse' })
    const {
      value: number,
      errorMessage: numberError,
      meta: numberMeta
    } = useField(
      'number', {
        required: true,
        alpha_num: true,
        min: 1
      }, { label: 'Haus-Nr.' })
    const {
      value: zip,
      errorMessage: zipError,
      meta: zipMeta
    } = useField(
      'zip', {
        required: true,
        min: 4,
        numeric: true
      }, { label: 'PLZ' })
    const {
      value: city,
      errorMessage: cityError,
      meta: cityMeta
    } = useField(
      'city', {
        required: true,
        min: 3
      }, { label: 'Ort' })
    const formValues = {
      lastname: props.person?.lastname
    }

    useForm({
      initialValues: formValues
    })

    const currentUser = ref(props.user || {})
    if (!currentUser.value) {
      // router.push({ name: 'AuthLogin' })
    }
    const isEdit = ref(props.edit || false)
    const isSaved = ref(false)
    const isLoading = ref(props.loading || false)
    let persId = ref('')
    let addressId = ref('')

    function setData () {
      persId = ref(props.person?.id as string)
      firstname.value = ref(props.person?.firstname)
      lastname.value = ref(props.person?.lastname)
      addressId = ref(props.address?.id as string)
      phone.value = ref(props.address?.phone)
      street.value = ref(props.address?.street)
      number.value = ref(props.address?.number)
      zip.value = ref(props.address?.zip)
      city.value = ref(props.address?.city)
    }

    watch(props, () => {
      setData()
    })

    async function setEdit () {
      isEdit.value = !isEdit.value
    }

    async function submit () {
      isLoading.value = true
      const person: Person = {
        firstname: firstname.value as string,
        lastname: lastname.value as string,
        type: 'user'
      }
      if (persId.value) person.id = persId.value
      try {
        const resPerson: ApiResponse = await createOrUpdatePerson(person)
        if (!resPerson || !resPerson.data || !resPerson.data.person) return
        persId = resPerson.data.person.id
        const address: Address = {
          phone: phone.value as string,
          street: street.value as string,
          number: number.value as string,
          zip: zip.value as string,
          city: city.value as string,
          refId: resPerson.data.person.id,
          refType: 'person',
          role: 'main'
        }
        if (addressId.value) address.id = addressId.value
        const resAddress: ApiResponse = await createOrUpdateAddress(address)
        if (!resAddress) return
        isSaved.value = true
        emit('addressSaved')
        setTimeout(() => {
          isSaved.value = false
        }, 5000)
        isLoading.value = false
        isEdit.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
      isLoading.value = false
    }

    return {
      setEdit,
      submit,
      isEdit,
      isSaved,
      isLoading,
      meta,
      firstname,
      firstnameError,
      firstnameMeta,
      lastname,
      lastnameError,
      lastnameMeta,
      phone,
      phoneMeta,
      phoneError,
      street,
      streetMeta,
      streetError,
      number,
      numberMeta,
      numberError,
      zip,
      zipMeta,
      zipError,
      city,
      cityMeta,
      cityError
    }
  }
})
