<template>
  <div class="card shadow h-100">
    <form class="form" v-on:submit.prevent="submit">
      <div class="card-header">
        <h3 class="d-inline-flex">Adressdaten</h3>
        <a class="text-black d-inline-flex float-end" href="#" v-on:click="setEdit" v-if="!isEdit" data-toggle="tooltip" title="bearbeiten">
          <h3>
            <b-icon-pencil/>
          </h3>
        </a>
        <a class="text-black d-inline-flex float-end" href="#" v-on:click="setEdit" v-if="isEdit" data-toggle="tooltip" title="bearbeiten">
          <h3>
            <b-icon-x/>
          </h3>
        </a>
      </div>
      <div class="card-body" v-if="!lastname && !isEdit">
        <p class="text-danger fw-bold">Du hast Deine Adressdaten noch nicht hinterlegt!</p>
        <p>
          Als Verkäufer bei der Babybörse kannst Du Dich erst zur Verlosung der Verkäufernummern anmelden,
          wenn Du Deine Adressdaten vollständig hinterlegt hast.
        </p>
      </div>
      <div class="card-body" v-else>
        <div class="alert alert-success" v-if="isSaved">
          <b-icon-check-circle-fill class="m-2" style="font-size: 2rem"/>
          <h5> Daten erfolgreich gespeichert!</h5>
        </div>
        <div class="row mb-2">
          <div class="col-4 text-end">
            <b>Vorname:</b>
          </div>
          <div class="col-8 text-start">
            <div v-if="isEdit">
              <input type="text" class="form-control"
                     v-bind:class="`${firstnameMeta.validated ? firstnameMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                     id="firstname" placeholder="Vorname" v-model="firstname">
              <div class="invalid-feedback" style="text-align: left">
                {{ firstnameError }}.
              </div>
            </div>
            <div v-else>{{ firstname }}</div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4 text-end">
            <b>Nachname:</b>
          </div>
          <div class="col-8 text-start">
            <div v-if="isEdit">
              <input type="text" class="form-control"
                     v-bind:class="`${lastnameMeta.validated ? lastnameMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                     id="lastname" placeholder="Nachname" v-model="lastname">
              <div class="invalid-feedback" style="text-align: left">
                {{ lastnameError }}.
              </div>
            </div>
            <div v-else>{{ lastname }}</div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4 text-end">
            <b>Telefon:</b>
          </div>
          <div class="col-8 text-start">
            <div v-if="isEdit">
              <input type="text" class="form-control"
                     v-bind:class="`${phoneMeta.validated ? phoneMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                     id="phone" placeholder="Telefon" v-model="phone">
              <div class="invalid-feedback" style="text-align: left">
                {{ phoneError }}.
              </div>
            </div>
            <div v-else>{{ phone }}</div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4 text-end">
            <b>Straße:</b>
          </div>
          <div class="col-8 text-start">
            <div v-if="isEdit">
              <div class="row gx-1">
                <div class="col-md-8">
                  <input type="text" class="form-control me-0"
                         v-bind:class="`${streetMeta.validated ? streetMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                         id="street" placeholder="Strasse" v-model="street">
                  <div class="invalid-feedback" style="text-align: left">
                    {{ streetError }}.
                  </div>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control ms-0"
                         v-bind:class="`${numberMeta.validated ? numberMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                         id="number" placeholder="Haus-Nr." v-model="number">
                  <div class="invalid-feedback" style="text-align: left">
                    {{ numberError }}.
                  </div>
                </div>
              </div>
            </div>
            <div v-else>{{ street }} {{ number }}</div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4 text-end">
            <b>PLZ, Ort:</b>
          </div>
          <div class="col-8 text-start">
            <div v-if="isEdit">
              <div class="row gx-1">
                <div class="col-md-4">
                  <input type="text" class="form-control me-0"
                         v-bind:class="`${zipMeta.validated ? zipMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                         id="zip" placeholder="PLZ" v-model="zip">
                  <div class="invalid-feedback" style="text-align: left">
                    {{ zipError }}.
                  </div>
                </div>
                <div class="col-md-8">
                  <input type="text" class="form-control ms-0"
                         v-bind:class="`${cityMeta.validated ? cityMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                         id="city" placeholder="Ort" v-model="city">
                  <div class="invalid-feedback" style="text-align: left">
                    {{ cityError }}.
                  </div>
                </div>
              </div>
            </div>
            <div v-else>{{ zip }} {{ city }}</div>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="isEdit && meta.valid">
        <button type="submit" class="btn btn-primary" :disabled="!meta.valid || isLoading">
          <span class="spinner-border spinner-border-sm" role="status" v-if="isLoading"></span>
          <span class="sr-only"> Speichern</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, toRefs, watch } from 'vue'
import { createOrUpdatePerson } from '@/services/api/person/api'
import { ApiResponse } from '@/services/api/api'
import { createOrUpdateAddress } from '@/services/api/address/api'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import { Person } from '@/services/api/person/interfaces'
import { Address } from '@/services/api/address/interfaces'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ShopPersonAddressForm',
  props: {
    user: Object,
    person: Object,
    address: Object,
    edit: Boolean,
    loading: Boolean
  },
  setup (props, { emit }) {
    const router = useRouter()
    const { meta } = useForm()
    const {
      value: firstname,
      errorMessage: firstnameError,
      meta: firstnameMeta
    } = useField(
      'firstname', {
        required: true,
        alpha_dash: true,
        min: 2
      }, { label: 'Vorname' })
    const {
      value: lastname,
      errorMessage: lastnameError,
      meta: lastnameMeta
    } = useField(
      'lastname', {
        required: true,
        alpha_dash: true,
        min: 2
      }, { label: 'Nachname' })
    const {
      value: phone,
      errorMessage: phoneError,
      meta: phoneMeta
    } = useField(
      'phone', {
        required: true,
        alpha_dash: true,
        min: 6
      }, { label: 'Telefon' })
    const {
      value: street,
      errorMessage: streetError,
      meta: streetMeta
    } = useField(
      'street', {
        required: true,
        min: 3
      }, { label: 'Strasse' })
    const {
      value: number,
      errorMessage: numberError,
      meta: numberMeta
    } = useField(
      'number', {
        required: true,
        alpha_num: true,
        min: 1
      }, { label: 'Haus-Nr.' })
    const {
      value: zip,
      errorMessage: zipError,
      meta: zipMeta
    } = useField(
      'zip', {
        required: true,
        min: 4,
        numeric: true
      }, { label: 'PLZ' })
    const {
      value: city,
      errorMessage: cityError,
      meta: cityMeta
    } = useField(
      'city', {
        required: true,
        min: 3
      }, { label: 'Ort' })
    const formValues = {
      lastname: props.person?.lastname
    }

    useForm({
      initialValues: formValues
    })

    const currentUser = ref(props.user || {})
    if (!currentUser.value) {
      // router.push({ name: 'AuthLogin' })
    }
    const isEdit = ref(props.edit || false)
    const isSaved = ref(false)
    const isLoading = ref(props.loading || false)
    let persId = ref('')
    let addressId = ref('')

    function setData () {
      persId = ref(props.person?.id as string)
      firstname.value = ref(props.person?.firstname)
      lastname.value = ref(props.person?.lastname)
      addressId = ref(props.address?.id as string)
      phone.value = ref(props.address?.phone)
      street.value = ref(props.address?.street)
      number.value = ref(props.address?.number)
      zip.value = ref(props.address?.zip)
      city.value = ref(props.address?.city)
    }

    watch(props, () => {
      setData()
    })

    async function setEdit () {
      isEdit.value = !isEdit.value
    }

    async function submit () {
      isLoading.value = true
      const person: Person = {
        firstname: firstname.value as string,
        lastname: lastname.value as string,
        type: 'user'
      }
      if (persId.value) person.id = persId.value
      try {
        const resPerson: ApiResponse = await createOrUpdatePerson(person)
        if (!resPerson || !resPerson.data || !resPerson.data.person) return
        persId = resPerson.data.person.id
        const address: Address = {
          phone: phone.value as string,
          street: street.value as string,
          number: number.value as string,
          zip: zip.value as string,
          city: city.value as string,
          refId: resPerson.data.person.id,
          refType: 'person',
          role: 'main'
        }
        if (addressId.value) address.id = addressId.value
        const resAddress: ApiResponse = await createOrUpdateAddress(address)
        if (!resAddress) return
        isSaved.value = true
        emit('addressSaved')
        setTimeout(() => {
          isSaved.value = false
        }, 5000)
        isLoading.value = false
        isEdit.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
      isLoading.value = false
    }

    return {
      setEdit,
      submit,
      isEdit,
      isSaved,
      isLoading,
      meta,
      firstname,
      firstnameError,
      firstnameMeta,
      lastname,
      lastnameError,
      lastnameMeta,
      phone,
      phoneMeta,
      phoneError,
      street,
      streetMeta,
      streetError,
      number,
      numberMeta,
      numberError,
      zip,
      zipMeta,
      zipError,
      city,
      cityMeta,
      cityError
    }
  }
})
</script>

<style scoped>

</style>
