
import { defineComponent, ref, toRef, toRefs, watch } from 'vue'
import { createOrUpdatePerson } from '@/services/api/person/api'
import { ApiResponse } from '@/services/api/api'
import { createOrUpdateAddress } from '@/services/api/address/api'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import { Person } from '@/services/api/person/interfaces'
import { Address } from '@/services/api/address/interfaces'
import { useRouter } from 'vue-router'
import { getUserSellerStatApi } from '@/services/api/userstats/api'

export default defineComponent({
  name: 'UserSellerStatComp',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const stat = ref()

    async function getUserSellerStat () {
      if (props.user) {
        try {
          stat.value = await getUserSellerStatApi()
        } catch (e) {
          return e
        }
      }
    }

    getUserSellerStat()

    function getCurrency (amount: number): string {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })

      return formatter.format(amount)
    }

    return {
      stat,
      getCurrency
    }
  }
})
