<template>
  <div class="profile container-lg">
    <h1>Profil von {{ currentUser.username }}</h1>
    <div class="row justify-content-center gx-3 row-eq-height">
      <div class="col-lg-6 mb-3">
        <UserProfileLoginData />
      </div>
      <div class="col-lg-6 mb-3">
        <ShopPersonAddressForm
          @address-saved="getPerson"
          :person="person" :address="address" :user="currentUser" :loading="isLoading"
        />
      </div>
    </div>
    <div class="row justify-content-center gx-3 row-eq-height">
    <div class="col-12 mt-3">
        <div class="card">
          <div class="card-header">
            <h2>Zur nächsten Babybörse anmelden</h2>
          </div>
          <div class="card-body">
            <p>Melde Dich ab sofort zur nächsten Babybörse als Verkäufer an.</p>
            <router-link class="btn btn-primary" :to="{ name: 'EventUserList' }">
              Jetzt als Verkäufer anmelden
            </router-link>
          </div>
        </div>
    </div>
    </div>
    <div class="row justify-content-center gx-3 row-eq-height">
      <div class="col-12 mt-3">
        <div class="card">
          <div class="card-header">
            <h2>Verkaufsdaten</h2>
          </div>
          <div class="card-body">
              <UserSellerStatComp :user="currentUser"></UserSellerStatComp>
          </div>
          <div class="card-footer p-0">
            <div class="alert alert-warning m-0 p-1 small">
              <p class="fw-bolder text-primary text-decoration-underline">Hinweise:</p>
              <p>Die Unterseite "Meine Verkaufsdaten" kannst Du auch über Deinen Account-Link (Menü oben rechts)
                direkt von jeder Seite aus aufrufen.</p>
              <p>Ab sofort siehst Du auch eine Übersicht Deiner verkauften Artikel per Klick auf:
                <router-link class="btn btn-primary btn-sm" :to="{ name: 'EventUserList' }">
                  <b-icon-caret-right-fill/>
                </router-link>
              </p>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import UserProfileLoginData from '@/components/user/ProfileLoginData.vue'
import { AUTH_STORE, SHOP_STORE } from '@/store/constants'
import { useStore } from '@/use/useStore'
import { useRouter } from 'vue-router'
import { getPersonForUser } from '@/services/api/person/api'
import { getAddressByRefRole } from '@/services/api/address/api'
import ShopPersonAddressForm from '@/components/shop/person/AddressForm.vue'
import { Shop } from '@/store/modules/shop/interfaces'
import UserSellerStatComp from '@/components/userstats/UserSellerStatComp.vue'

export default defineComponent({
  name: 'UserProfile',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  components: { UserSellerStatComp, ShopPersonAddressForm, UserProfileLoginData },
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    if (!currentUser.value) {
      // router.push({ name: 'AuthLogin' })
    }
    const isLoading = ref(false)
    const person = ref()
    const address = ref()

    async function getPerson () {
      try {
        person.value = await getPersonForUser()
        if (person.value) {
          address.value = await getAddressByRefRole(person.value.id, 'person', 'main')
        }
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
    }

    getPerson()

    return {
      currentUser,
      person,
      address,
      isLoading,
      getPerson
    }
  }
})
</script>

<style scoped>

</style>
