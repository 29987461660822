import { addressApi, ApiError, ApiResponse, getResponseData, handleApiErrors } from '@/services/api/api'
import { CurrentUser } from '@/store/modules/auth/interfaces'
import { Address } from '@/services/api/address/interfaces'
import { getLocalStorage } from '@/services/utils/localStorage'
import { authServices } from '@/services/api/auth/api'

/**
 * create or update person
 * @param data
 */
async function createOrUpdateAddress (data: any): Promise<ApiResponse> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return addressApi.post('/', data, config)
    .then((response) => {
      const data = getResponseData(response)
      return Promise.resolve(data)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get address for ref and role
 * @param ref
 * @param refType
 * @param role
 */
async function getAddressByRefRole (ref: string, refType: string, role: string): Promise<Address> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return addressApi.get(`/getByRefRole/${ref}/${refType}/${role}`, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data.address) {
        return Promise.resolve(res.data.data.address)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

export {
  createOrUpdateAddress,
  getAddressByRefRole
}
