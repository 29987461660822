
import { computed, defineComponent, PropType, ref } from 'vue'
import UserProfileLoginData from '@/components/user/ProfileLoginData.vue'
import { AUTH_STORE, SHOP_STORE } from '@/store/constants'
import { useStore } from '@/use/useStore'
import { useRouter } from 'vue-router'
import { getPersonForUser } from '@/services/api/person/api'
import { getAddressByRefRole } from '@/services/api/address/api'
import ShopPersonAddressForm from '@/components/shop/person/AddressForm.vue'
import { Shop } from '@/store/modules/shop/interfaces'
import UserSellerStatComp from '@/components/userstats/UserSellerStatComp.vue'

export default defineComponent({
  name: 'UserProfile',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  components: { UserSellerStatComp, ShopPersonAddressForm, UserProfileLoginData },
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    if (!currentUser.value) {
      // router.push({ name: 'AuthLogin' })
    }
    const isLoading = ref(false)
    const person = ref()
    const address = ref()

    async function getPerson () {
      try {
        person.value = await getPersonForUser()
        if (person.value) {
          address.value = await getAddressByRefRole(person.value.id, 'person', 'main')
        }
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
    }

    getPerson()

    return {
      currentUser,
      person,
      address,
      isLoading,
      getPerson
    }
  }
})
