
import { computed, defineComponent } from 'vue'
import { AUTH_STORE } from '@/store/constants'
import { useStore } from '@/use/useStore'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'UserProfileLoginData',
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    if (!currentUser.value) {
      // router.push({ name: 'AuthLogin' })
    }
    return {
      currentUser
    }
  }
})
