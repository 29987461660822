<template>
  <div class="card shadow h-100">
    <div class="card-header">
      <h3>Anmeldedaten</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4 text-end">
          <b>Name:</b>
        </div>
        <div class="col-8 text-start">
          {{ currentUser.username }}
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end">
          <b>E-Mail:</b>
        </div>
        <div class="col-8 text-start">
          {{ currentUser.email }}
        </div>
      </div>
    </div>
    <div class="card-footer">
      <router-link class="btn btn-primary" :to="{ name: 'AuthNewPwdToken' }">Passwort ändern</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { AUTH_STORE } from '@/store/constants'
import { useStore } from '@/use/useStore'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'UserProfileLoginData',
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    if (!currentUser.value) {
      // router.push({ name: 'AuthLogin' })
    }
    return {
      currentUser
    }
  }
})
</script>

<style scoped>

</style>
