import { ApiError, ApiResponse, getResponseData, handleApiErrors, personApi } from '@/services/api/api'
import { CurrentUser } from '@/store/modules/auth/interfaces'
import { Person } from '@/services/api/person/interfaces'
import { getLocalStorage } from '@/services/utils/localStorage'
import { authServices } from '@/services/api/auth/api'

/**
 * create or update person
 * @param data
 */
async function createOrUpdatePerson (data: any): Promise<ApiResponse> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return personApi.post('/', data, config)
    .then((response) => {
      const data = getResponseData(response)
      return Promise.resolve(data)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get person for persId
 * @param id
 */
async function getOneByIdApi (id: string): Promise<Person> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return personApi.get('/getOneById' + id, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.person) {
        return Promise.resolve(res.data.data.person)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get person for current user by token
 */
async function getPersonForUser (): Promise<Person> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return personApi.get('/getForUser', config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.person) {
        return Promise.resolve(res.data.data.person)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get all person for current user by token
 */
async function getAllPersonsForUserApi (): Promise<Person[]> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return personApi.get('/getAllByCurrentUser', config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.persons) {
        return Promise.resolve(res.data.data.persons)
      }
      return Promise.resolve([])
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get person for sellerId
 * @param sellerId
 */
async function getPersonBySellerIdApi (sellerId: string): Promise<Person> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return personApi.get('/getOneBySellerId/' + sellerId, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.person) {
        return Promise.resolve(res.data.data.person)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

export {
  createOrUpdatePerson,
  getOneByIdApi,
  getPersonForUser,
  getPersonBySellerIdApi,
  getAllPersonsForUserApi
}
