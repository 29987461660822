<template>
  <div class="row g-2 row-eq-height justify-content-center mt-2" v-if="stat">
    <div class="col-12 col-sm-6 col-xl-3" v-if="stat.current.eventCount > 0">
      <div class="card h-100">
        <div class="card-header bg-success text-white">
          <h5>Aktueller Verkauf</h5>
        </div>
        <div class="card-body">
          <div class="row row-eq-height align-items-center">
            <div class="col-4"><b-icon-person-check-fill class="text-success fs-1"/></div>
            <div class="col-8 text-start">
              <p><span class="fs-2 fw-bold">{{ stat.current.bookingCount }}</span><br>Artikel verkauft</p>
              <p><span class="fs-2 fw-bold">{{ getCurrency(stat.current.sellerSum) }}</span><br>Erlös</p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <router-link class="btn btn-primary" :to="{ name: 'EventUserList' }">mehr Details</router-link>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-xl-3" v-if="stat.last.eventCount > 0">
      <div class="card h-100">
        <div class="card-header">
          <h5>Letzter Verkauf</h5>
        </div>
        <div class="card-body">
          <div class="row row-eq-height align-items-center">
            <div class="col-4"><b-icon-check-lg class="text-success fs-1"/></div>
            <div class="col-8 text-start">
              <p><span class="fs-2 fw-bold">{{ stat.last.bookingCount }}</span><br>Artikel verkauft</p>
              <p><span class="fs-2 fw-bold">{{ getCurrency(stat.last.sellerSum) }}</span><br>Erlös</p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <router-link class="btn btn-primary" :to="{ name: 'EventUserList' }">mehr Details</router-link>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-xl-3" v-if="stat.finished.eventCount > 0">
      <div class="card h-100">
        <div class="card-header">
          <h5>Alle Verkäufe</h5>
        </div>
        <div class="card-body">
          <div class="row row-eq-height align-items-center">
            <div class="col-4"><b-icon-piggy-bank-fill class="text-success fs-1"/></div>
            <div class="col-8 text-start">
              <p>
                <span class="fs-2 fw-bold">
                  {{ stat.current.eventCount + stat.finished.eventCount }}
                </span>
                <br>Termin<span v-if="stat.current.eventCount + stat.finished.eventCount > 1">e</span>
              </p>
              <p><span class="fs-2 fw-bold">
                {{ stat.current.bookingCount + stat.finished.bookingCount }}
              </span><br>Artikel verkauft</p>
              <p><span class="fs-2 fw-bold">
                {{ getCurrency(stat.current.sellerSum + stat.finished.sellerSum) }}
              </span><br>Erlös</p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <router-link class="btn btn-primary" :to="{ name: 'EventUserList' }">mehr Details</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, toRefs, watch } from 'vue'
import { createOrUpdatePerson } from '@/services/api/person/api'
import { ApiResponse } from '@/services/api/api'
import { createOrUpdateAddress } from '@/services/api/address/api'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import { Person } from '@/services/api/person/interfaces'
import { Address } from '@/services/api/address/interfaces'
import { useRouter } from 'vue-router'
import { getUserSellerStatApi } from '@/services/api/userstats/api'

export default defineComponent({
  name: 'UserSellerStatComp',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const stat = ref()

    async function getUserSellerStat () {
      if (props.user) {
        try {
          stat.value = await getUserSellerStatApi()
        } catch (e) {
          return e
        }
      }
    }

    getUserSellerStat()

    function getCurrency (amount: number): string {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })

      return formatter.format(amount)
    }

    return {
      stat,
      getCurrency
    }
  }
})
</script>

<style scoped>

</style>
